
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ShippingProduct } from "@/store/modules/ShippingProductModule";

export default defineComponent({
  name: "shipping-products",
  setup() {
    const { t } = useI18n();
    
    onMounted(() => {
      setCurrentPageBreadcrumbs("发货商品管理", ["发货管理"]);
    });

    const store = useStore();
    const search = ref("");
    const userMobile = ref("");
    const status = ref("pending");
    const purchased = ref(null);
    const tableData = ref<ShippingProduct[]>([]);
    
    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    });

    // 定义items作为计算属性
    const items = computed(() => store.getters.currentShippingProducts);
    const total = computed(() => store.getters.currentShippingProductCount);
    
    const loadData = () => {
      const params: any = {
        currentPage: page.value.currentPage,
        pageSize: page.value.pageSize,
        product_name: search.value,
        user_mobile: userMobile.value,
        status: status.value
      };

      if (purchased.value !== null) {
        params.purchased = purchased.value;
      }

      store.dispatch(Actions.FETCH_SHIPPING_PRODUCTS, params).then(() => {
        tableData.value = store.getters.currentShippingProducts;
        page.value.totalResult = store.getters.currentShippingProductCount;
      });
    };

    const handleSearch = () => {
      page.value.currentPage = 1;
      loadData();
    };

    const onPageChange = (evt: any) => {
      const { type, currentPage, pageSize } = evt;
      
      if (type === 'size') {
        page.value.currentPage = 1;
      } else {
        page.value.currentPage = currentPage;
      }
      
      page.value.pageSize = pageSize;
      loadData();
    };

    watch([status, purchased], () => {
      page.value.currentPage = 1;
      loadData();
    });

    onMounted(() => {
      loadData();
    });

    const getStatusClass = (status: string) => {
      switch (status) {
        case 'delivered':
          return 'badge badge-light-success';
        case 'canceled':
          return 'badge badge-light-danger';
        case 'pending':
        default:
          return 'badge badge-light-warning';
      }
    };

    const getStatusText = (status: string) => {
      switch (status) {
        case 'delivered':
          return t('delivered');
        case 'canceled':
          return t('cancelled');
        case 'pending':
        default:
          return t('pending');
      }
    };

    const editingId = ref<number | null>(null);
    const editForm = ref({
      purchased: false,
      purchase_price: 0,
      name: '',
      mobile: '',
      area: '',
      address_detail: '',
      note: ''
    });

    const startEdit = (item: any) => {
      editingId.value = item.id;
      editForm.value = {
        purchased: item.purchased,
        purchase_price: item.purchase_price,
        name: item.name,
        mobile: item.mobile,
        area: item.area,
        address_detail: item.address_detail,
        note: item.note
      };
    };

    const cancelEdit = () => {
      editingId.value = null;
      editForm.value = {
        purchased: false,
        purchase_price: 0,
        name: '',
        mobile: '',
        area: '',
        address_detail: '',
        note: ''
      };
    };

    const handleSave = async (item: any) => {
      try {
        await store.dispatch(Actions.UPDATE_SHIPPING_PRODUCT, {
          id: item.id,
          purchased: editForm.value.purchased,
          purchase_price: editForm.value.purchase_price,
          name: editForm.value.name,
          mobile: editForm.value.mobile,
          area: editForm.value.area,
          address_detail: editForm.value.address_detail,
          note: editForm.value.note
        });
        
        Swal.fire({
          text: t('update_success'),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        
        loadData();
        cancelEdit();
      } catch (error) {
        Swal.fire({
          text: t('update_fail'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    // 批量选择相关
    const selectedItems = ref<number[]>([]);
    const isAllSelected = computed(() => {
      const currentItems = items.value;
      return currentItems.length > 0 && selectedItems.value.length === currentItems.length;
    });

    const toggleAll = () => {
      const currentItems = items.value;
      if (isAllSelected.value) {
        selectedItems.value = [];
      } else {
        selectedItems.value = currentItems.map(item => item.id);
      }
    };

    // 发货弹窗相关
    const deliverDialogVisible = ref(false);
    const deliverForm = ref({
      shipping_provider: '',
      shipping_no: ''
    });
    const deliverRules = {
      shipping_provider: [{ required: false, message: t('please_input_shipping_provider'), trigger: 'blur' }],
      shipping_no: [{ required: true, message: t('please_input_shipping_no'), trigger: 'blur' }]
    };
    const deliverFormRef = ref();

    // 重置发货表单
    const resetDeliverForm = () => {
      deliverForm.value = {
        shipping_provider: '',
        shipping_no: ''
      };
      if (deliverFormRef.value) {
        deliverFormRef.value.resetFields();
      }
    };

    // 关闭发货弹窗
    const closeDeliverDialog = () => {
      deliverDialogVisible.value = false;
      resetDeliverForm();
    };

    const handleBatchDeliver = () => {
      const currentItems = items.value;
      const selectedDetails = currentItems.filter(item => selectedItems.value.includes(item.id));
      
      // 检查地址是否相同
      const firstAddress = selectedDetails[0];
      const hasDifferentAddress = selectedDetails.some(detail => 
        detail.name !== firstAddress.name ||
        detail.mobile !== firstAddress.mobile ||
        detail.area !== firstAddress.area ||
        detail.address_detail !== firstAddress.address_detail
      );

      if (hasDifferentAddress) {
        Swal.fire({
          text: t('different_address_error'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }

      // 检查是否有已发货商品
      const hasDelivered = selectedDetails.some(detail => detail.status === 'delivered');
      if (hasDelivered) {
        Swal.fire({
          text: t('delivered_error'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }

      // 检查是否有未采购或采购价为空的商品
      const hasUnpurchased = selectedDetails.some(detail => 
        !detail.purchased || !detail.purchase_price
      );
      if (hasUnpurchased) {
        Swal.fire({
          text: t('unpurchased_error'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }

      deliverDialogVisible.value = true;
    };

    const submitDeliver = async () => {
      if (!deliverFormRef.value) return;
      
      await deliverFormRef.value.validate(async (valid: boolean) => {
        if (valid) {
          try {
            const response = await store.dispatch(Actions.BATCH_DELIVER_SHIPPING_PRODUCTS, {
              detail_ids: selectedItems.value,
              shipping_provider: deliverForm.value.shipping_provider,
              shipping_no: deliverForm.value.shipping_no
            });

            // 检查返回的success字段
            if (response && response.success === false) {
              Swal.fire({
                text: response.error_msg || t('deliver_fail'),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t('confirm'),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
              return;
            }

            Swal.fire({
              text: t('deliver_success'),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t('confirm'),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });

            deliverDialogVisible.value = false;
            selectedItems.value = [];
            loadData();
          } catch (error: any) {
            Swal.fire({
              text: error.response?.data?.error_msg || "发货失败",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "确定",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          }
        }
      });
    };

    // 添加 loading 计算属性
    const loading = computed(() => store.state.ShippingProductModule.loading);

    const handleCancel = async (id: number) => {
      try {
        const result = await Swal.fire({
          text: t('confirm_cancel'),
          icon: "warning",
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
            cancelButton: "btn fw-bold btn-active-light-primary"
          }
        });

        if (result.isConfirmed) {
          await store.dispatch(Actions.CANCEL_SHIPPING_PRODUCT, id);
          
          Swal.fire({
            text: t('cancel_success'),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t('confirm'),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          
          loadData();
        }
      } catch (error) {
        Swal.fire({
          text: t('cancel_fail'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    const onDownload = async () => {
      try {
        const params = {
          product_name: search.value,
          user_mobile: userMobile.value,
          status: status.value,
          purchased: purchased.value
        };

        const response = await store.dispatch(Actions.DOWNLOAD_SHIPPING_PRODUCTS, params);
        
        // 创建一个临时的 a 标签来下载文件
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'shipping_products.xlsx'); // 或者使用后端返回的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        Swal.fire({
          text: t('download_fail'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    return {
      search,
      userMobile,
      status,
      purchased,
      page,
      items,  // 返回计算属性
      handleSearch,
      onPageChange,
      getStatusClass,
      getStatusText,
      editingId,
      editForm,
      startEdit,
      cancelEdit,
      handleSave,
      selectedItems,
      isAllSelected,
      toggleAll,
      deliverDialogVisible,
      deliverForm,
      deliverRules,
      deliverFormRef,
      handleBatchDeliver,
      submitDeliver,
      resetDeliverForm,
      closeDeliverDialog,
      loading, // 添加 loading 到返回值
      handleCancel,
      onDownload,
    };
  },
});
